<template>
	<v-card class="pa-0 device-advanced">
		<v-tabs height="30" v-model="selectedTabAdvanced" :color="primaryColor" background-color="rgba(0, 0, 0, 0)" show-arrows>
			<v-tab ref="advanced-device" href="#advanced-device">{{ $t('panels.device.advanced-device-tab') }}</v-tab>
			<v-tab ref="advanced-datasource" href="#advanced-datasource">{{ $t('panels.device.advanced-datasource-tab') }}</v-tab>
		</v-tabs>
		<v-card-text class="pa-0">
			<v-tabs-items v-model="selectedTabAdvanced" class="width-100">
				<v-tab-item value="advanced-device" class="width-100">
					<advanced-chart :displayLocation="displayLocation" :pollutants="pollutants" :deviceObject="deviceObject" />
				</v-tab-item>
				<v-tab-item value="advanced-datasource" class="width-100">
					<advanced-datasource-chart :feature="deviceObject" :displayLocation="displayLocation" :pollutants="pollutants" />
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
	</v-card>
</template>

<script>
import AdvancedChart from '@/components/echart/AdvancedChart.vue';
import AdvancedDatasourceChart from '@/components/echart/AdvancedDatasourceChart.vue';
export default {
	components: {
		AdvancedChart,
		AdvancedDatasourceChart
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		selectedTabAdvanced: {
			get() {
				return this.$store.getters.getSelectedTabAdvanced;
			},
			set(value) {
				this.$store.commit('setSelectedTabAdvanced', value);
			}
		}
	},
	watch: {
		deviceObject(val, prev) {
			console.log('device watcher advanced', prev, val);
			this.$store.commit('setSelectedTabAdvanced', 'advanced-device');
		}
	},
	mounted() {
		console.log('mounted device advanced', this.displayMode);
	},
	methods: {
		setupAdvancedStore() {
			console.warn('setupAdvancedStore', this.$store.getters.getAdvancedDatasourceStore);
			this.$store.commit('setFormAdvancedDatasourceObject', {
				index: this.deviceObject.code,
				data: {
					index: this.deviceObject.code,
					dateInterval: null,
					startDate: null,
					endDate: null,
					formDisabled: false,
					startDateDisabled: true,
					endDateDisabled: true,
					infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
					infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
					chartData: null,
					option: null,
					designOption: null,
					configurations: [],
					showChartOptions: false,
					showIntervals: false,
					selectedDevices: [],
					selectedPollutant: null
				}
			});
			console.log('advancedStore', this.$store.getters.getHistoricStore);
		}
	}
};
</script>

<style lang="postcss">
.switchoff {
	color: var(--gris-300);

	& .switchoff_icon {
		font-size: 2rem;
	}

	& .switchoff-text {
		font-size: 1rem;
	}
}
</style>
