<template>
	<v-card class="pa-0 device-historic" v-if="pollutants">
		<v-tabs fixed-tabs v-model="selectedTabHistoric" :color="primaryColor">
			<v-tab v-for="pollutant in pollutants" :key="pollutants.indexOf(pollutant)" :value="pollutants.indexOf(pollutant)">
				<v-tooltip top :color="primaryColor + 'd9'">
					<template v-slot:activator="{ on, attrs }">
						<span class="width-100" v-bind="attrs" v-on="on">{{ pollutant.acronym }}</span>
					</template>
					<span class="fs-regular">{{ pollutant.name }}</span>
				</v-tooltip>
			</v-tab>
			<!-- <v-tab
				v-for="pollutant in pollutants"
				:key="pollutants.indexOf(pollutant)"
				:value="pollutants.indexOf(pollutant)"
				:title="pollutant.name"
			>
				{{ pollutant.acronym }}
			</v-tab> -->
		</v-tabs>
		<v-card-text class="pa-0">
			<v-window v-model="selectedTabHistoric">
				<v-window-item v-for="pollutant in pollutants" :key="pollutants.indexOf(pollutant)" :value="pollutants.indexOf(pollutant)">
					<v-card flat>
						<historic-chart
							:feature="deviceObject"
							:pollutant="pollutant"
							:chartData="historicElasticData[pollutant.elasticacronym]"
							:displayLocation="displayLocation"
						/>
					</v-card>
				</v-window-item>
			</v-window>
		</v-card-text>
	</v-card>
	<v-card v-else class="switchoff d-flex flex-column align-center justify-center">
		<v-icon size="2rem"> fal fa-inbox </v-icon>
		<div class="switchoff-text">{{ $t('common.noData') }}</div>
	</v-card>
</template>

<script>
import HistoricChart from '@/components/echart/HistoricChart.vue';
import { lastData } from '@/api/databases_API';
// eslint-disable-next-line no-unused-vars
import { search, obtainIndexName } from '@/api/common';
import { query_FirstDataByParameter } from '@/utils/queries';

export default {
	components: {
		HistoricChart
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			tab: 0,
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],
			infoStartDate: this.startDateDisabled ? this.$t('aqi.infoDateIntervalDisabled') : this.$t('aqi.infoStartDate'),
			infoEndDate: this.endDateDisabled ? this.$t('aqi.infoDateIntervalDisabled') : this.$t('aqi.infoEndDate'),
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		historicElasticData() {
			return this.$store.getters.getHistoricElasticData;
		},
		historicFormData() {
			return this.$store.getters.getHistoricStore;
		},
		selectedTabHistoric: {
			get() {
				return this.$store.getters.getSelectedTabHistoric;
			},
			set(value) {
				this.$store.commit('setSelectedTabHistoric', value);
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		}
	},
	watch: {},
	created() {
		console.warn('HistoricStore', this.$store.getters.getHistoricStore);
		if (this.displayLocation === 'card') {
			this.setupHistoricStore();
		}
	},
	mounted() {
		console.warn('mounted device historic', this.deviceObject, this.pollutants);

		var d = new Date();
		d.setDate(d.getDate() - 2);
		console.warn(d);
		console.warn(d.valueOf());

		const promise = lastData(
			obtainIndexName(this.deviceObject),
			query_FirstDataByParameter(this.deviceObject.code, this.pollutants[0].elasticacronym)
		);
		promise.then((elasticData) => {
			console.warn('Resultado HISTORIC ' + this.pollutants[0].elasticacronym + ' ' + this.deviceObject.code, elasticData);
			console.log('QUERY: ', query_FirstDataByParameter(this.deviceObject.code, this.pollutants[0].elasticacronym));

			if (typeof elasticData.message[0] == 'object') {
				let timestamp = elasticData.message[0]['_source']['dateObserved_timestamp'];
				let fecha = new Date(timestamp);
				console.log('date obtained', timestamp, fecha);
			}
		});
	},
	updated() {
		console.warn('TAB: ' + this.tab, this.selectedTabHistoric);
		console.warn('Pollutant: ' + this.pollutants[this.selectedTabHistoric].elasticacronym);
		console.warn(this.pollutants[this.selectedTabHistoric]);
		console.warn(this.historicElasticData);
	},
	methods: {
		setupHistoricStore() {
			console.warn('setupHistoricStore', this.$store.getters.getHistoricStore, this.$store.getters.getHistoricStore.length);
			if (this.$store.getters.getHistoricStore.length === 0) {
				this.pollutants.forEach((pollutant) => {
					this.$store.commit('setFormHistoricObject', {
						index: pollutant.elasticacronym,
						data: {
							index: pollutant.elasticacronym,
							dateInterval: null,
							startDate: null,
							endDate: null,
							formDisabled: false,
							startDateDisabled: true,
							endDateDisabled: true,
							infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
							infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
							chartData: null,
							option: null,
							designOption: null,
							configurations: [],
							parameterConfiguration: {},
							showIntervals: false
						}
					});
				});
			}
			console.log('historicStore', this.$store.getters.getHistoricStore);
		}
	}
};
</script>

<style lang="postcss">
.switchoff {
	color: var(--gris-300);
	& .switchoff_icon {
		font-size: 2rem;
	}
	& .switchoff-text {
		font-size: 1rem;
	}
}
</style>
