<template>
	<div class="maptools rounded elevation-1">
		<div class="d-flex">
			<map-download-pdf v-if="showDownloadPDF && !isActiveTabCompareMaps" />
			<map-download-png v-if="showDownloadPNG && !isActiveTabCompareMaps" />
			<map-measure-tool v-if="showMeasureTool && !isActiveTabCompareMaps" />
			<map-zoom-reset></map-zoom-reset>
			<map-zooms-control />
		</div>
	</div>
</template>

<script>
import MapMeasureTool from '@/components/map/sections/map/subcomponents/MapMeasureTool';
import MapZoomsControl from '@/components/map/sections/map/subcomponents/MapZoomsControlMap';
import MapDownloadPng from '@/components/map/sections/map/subcomponents/MapDownloadPNG';
import MapDownloadPdf from '@/components/map/sections/map/subcomponents/MapDownloadPDF';
import MapZoomReset from '@/components/map/sections/map/subcomponents/MapZoomReset';
import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';
export default {
	name: 'maptools',
	data() {
		return {
			showDownloadPDF: true,
			showDownloadPNG: false,
			showMeasureTool: true
		};
	},
	components: {
		MapMeasureTool,
		MapZoomsControl,
		MapDownloadPng,
		MapDownloadPdf,
		MapZoomReset
	},
	computed: {
		isActiveTabCompareMaps() {
			return this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID;
		}
	},
	watch: {},
	methods: {}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.maptools {
	position: absolute;
	bottom: calc(var(--map-control-bottom-position) + 2rem);
	//transition: all 1s ease-out;
	z-index: 3;
	right: 1%;
	height: 32px;
	background-color: var(--negative);
	opacity: 0.85 !important;
}
</style>
