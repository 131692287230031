<template>
	<!-- <div :style="appearance"> -->
	<v-card class="width-100 height-100 overflow-hidden" style="border-top-right-radius: 0px; border-top-left-radius: 0px">
		<div v-if="deviceObject.spatialmodel == satelliteModel || deviceObject.elasticacronym == satelliteType">
			<v-tabs v-model="tab" align-tabs="title">
				<v-tab v-for="item in deviceTabsSatellite" :key="item.value" :value="item.value">
					{{ $t(item.label) }}
				</v-tab>
			</v-tabs>

			<v-card-text class="pa-0 overflow-hidden ma-0">
				<v-window v-model="tab">
					<v-window-item v-for="item in deviceTabsSatellite" :key="item.value" :value="item.value">
						<v-card class="pa-0" flat>
							<component :deviceObject="deviceObject" :is="item.component" :displayLocation="displayLocation" />
						</v-card>
					</v-window-item>
				</v-window>
			</v-card-text>
		</div>
		<div v-else>
			<v-tabs v-model="selectedTabDevice" align-tabs="title">
				<v-tab v-for="item in deviceTabs" :key="item.value" :ref="item.value" :href="'#' + item.value"> {{ $t(item.label) }} </v-tab>
			</v-tabs>
			<!-- :value="item.value" -->

			<v-card-text class="pa-0 overflow-hidden ma-0" :style="panelStyle">
				<v-window v-model="selectedTabDevice">
					<v-window-item v-for="item in deviceTabs" :key="item.value" :value="item.value">
						<v-card class="pa-0" flat>
							<component
								:deviceObject="deviceObject"
								:notificationObject="deviceObject"
								:is="item.component"
								:displayLocation="displayLocation"
							/>
						</v-card>
						<!-- <v-card
							v-if="item.component == 'NotificationHistoric' && displayLocation == displayMode && selectedTabDevice == 7"
							class="pa-0"
							flat
						>
							<component
								:deviceObject="deviceObject"
								:notificationObject="deviceObject"
								:is="item.component"
								:displayLocation="displayLocation"
							/>
						</v-card>
						<v-card v-else class="pa-0" flat> </v-card> -->
					</v-window-item>
				</v-window>
			</v-card-text>
		</div>
	</v-card>
	<!-- </div> -->
</template>

<script>
import DeviceHistoric from './DeviceHistoric.vue';
import DeviceRealTime from './DeviceRealTime.vue';
import DevicePrediction from './DevicePrediction.vue';
import DeviceXAI from './DeviceXAI.vue';
import DeviceAdvanced from './DeviceAdvanced.vue';
import DeviceHistoricSatellite from './DeviceHistoricSatellite.vue';
import DeviceRealTimeSatellite from './DeviceRealTimeSatellite.vue';
import DevicePredictionSatellite from './DevicePredictionSatellite.vue';
import DeviceCompareAqi from './DeviceCompareAQI.vue';

import NotificationHistoric from '../notification/NotificationHistoric.vue';
import NotificationActive from '../notification/NotificationActive.vue';
// eslint-disable-next-line no-unused-vars
import { query_LastDataHistoric } from '@/utils/queries';
//import { lastData } from '@/api/databases_API';
import { CARD_CONTAINER_HEIGHT } from '@/lib/variables/panels';
import { obtainPanelRealTimeData, obtainIndexName, obtainPanelHistoricFirstData, obtainPanelPredictionsDateRange } from '@/api/common';

import constants from '@/utils/constants';

import { lastData } from '@/api/databases_API';

export default {
	name: 'DeviceIndex',
	components: {
		DeviceHistoric,
		DeviceAdvanced,
		DeviceXAI,
		DeviceRealTime,
		DevicePrediction,
		DeviceHistoricSatellite,
		DeviceRealTimeSatellite,
		DevicePredictionSatellite,
		DeviceCompareAqi,
		NotificationHistoric,
		NotificationActive
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			appearance: {
				height: '100%',
				width: '100%',
				overflow: 'hidden'
				//position: 'absolute'
			},
			satelliteType: constants.DATASOURCETYPE_SATELLITE,
			satelliteModel: constants.SATELLITE_MODEL,
			panelStyle: {
				height: CARD_CONTAINER_HEIGHT,
				overflow: 'scroll'
			},
			tab: null,
			showPredictionsXai: false,
			tabIndex: null,
			deviceTabs: [
				{ label: 'panels.device.realTime', component: 'DeviceRealTime', value: constants.FEATURE_TABS.REAL_TIME },
				{ label: 'panels.device.historic', component: 'DeviceHistoric', value: constants.FEATURE_TABS.HISTORIC },
				{ label: 'panels.device.advancedChart', component: 'DeviceAdvanced', value: constants.FEATURE_TABS.ADVANCED }
			],
			deviceTabsSatellite: [
				/*{ label: 'panels.device.historic', component: 'DeviceHistoricSatellite', value: 0 },
				{ label: 'panels.device.realTime', component: 'DeviceRealTimeSatellite', value: 1 },
				{ label: 'panels.device.predictions', component: 'DevicePredictionSatellite', value: 0 }*/
			],
			formAdvanced: {
				startDate: null,
				endDate: null,
				selectedOptions: [],
				option: null,
				chartData: null,
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'ADVANCED_CHART_COMPARE_PROPERTIES'
				}
			}
		};
	},
	computed: {
		realTimeElasticData: {
			get() {
				return this.$store.getters.getRealTimeElasticData;
			},
			set(value) {
				this.$store.commit('setRealTimeElasticData', value);
			}
		},
		historicFirstDates() {
			return this.$store.getters.getHistoricFirstDates;
		},
		historicLastDates() {
			return this.$store.getters.getHistoricLastDates;
		},
		predictionFirstDates() {
			return this.$store.getters.getPredictionFirstDates;
		},
		predictionLastDates() {
			return this.$store.getters.getPredictionLastDates;
		},
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		hasActiveNotifications() {
			return this.featureActiveNotifications || this.calledFromNotification;
		},
		featureActiveNotifications() {
			if (this.$store.getters.getNotifications && this.$store.getters.getNotifications.length > 0) {
				return this.$store.getters.getNotifications.filter(
					(notification) => notification.pmdatasourceinstanceid == this.deviceObject.pmdatasourceinstanceid
				);
			} else {
				return false;
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		}
	},
	watch: {
		tab: function () {
			console.log('device tab changed', this.tab);
		},
		selectedTabDevice: function () {
			console.log('selected tab changed', this.selectedTabDevice);
		},
		deviceObject: {
			handler(newValue, oldValue) {
				console.debug('deviceObject watcher', oldValue.code, newValue.code);
				if (newValue && newValue.code != oldValue.code) {
					let indexName = obtainIndexName(this.deviceObject);
					if (indexName) obtainPanelRealTimeData(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}
			},
			deep: true
		}
	},
	beforeCreate() {
		this.$vuetify.lang.current = this.$store.getters.getSession.language;
	},
	mounted() {
		console.log('mounted device index', this.displayMode);
		this.$store.commit('setFormAdvancedObject', this.formAdvanced);
		this.$store.commit('setAdvancedHistoricElasticData', null);
		this.$store.commit('setAdvancedDatasourceElasticData', null);
		const indexName = obtainIndexName(this.deviceObject);
		console.log('info: indexName: ', indexName);
		if (indexName) {
			if (this.deviceObject.spatialmodel !== this.satelliteModel && this.deviceObject.elasticacronym !== this.satelliteType) {
				//this.obtainLastData(this.deviceObject.code);
				console.warn('realTimeElasticData index', this.realTimeElasticData, this.realTimeElasticData.length);
				if (this.realTimeElasticData.length === 0) {
					obtainPanelRealTimeData(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}
				if (this.historicFirstDates.length === 0) {
					obtainPanelHistoricFirstData(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}

				//console.log('mounted device', this.historicFirstDates, this.historicLastDates);
			} else {
				if (this.predictionFirstDates.length == 0 || this.predictionLastDates.length == 0) {
					obtainPanelPredictionsDateRange(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}
			}
		} else {
			this.$puiNotify.error(this.$t('common.noElasticIndex'));
		}
		//Add predictions XAI
		if (this.showPredictionsXai == true) {
			this.deviceTabs = [
				{ label: 'panels.device.realTime', component: 'DeviceRealTime', value: constants.FEATURE_TABS.REAL_TIME },
				{ label: 'panels.device.historic', component: 'DeviceHistoric', value: constants.FEATURE_TABS.HISTORIC },
				{ label: 'panels.device.predictions', component: 'DevicePrediction', value: constants.FEATURE_TABS.PREDICTIONS },
				{ label: 'panels.device.advancedChart', component: 'DeviceAdvanced', value: constants.FEATURE_TABS.ADVANCED },
				{ label: 'panels.device.xai', component: 'DeviceXAI', value: constants.FEATURE_TABS.XAI }
			];
		}
		// Add notification tabs

		if (this.userProperties.showaqi == 1) {
			this.deviceTabs.push({ label: 'panels.device.compareAQI', component: 'DeviceCompareAqi', value: constants.FEATURE_TABS.AQI });
			this.tabIndex++;
		}
		if (this.hasActiveNotifications) {
			this.deviceTabs.push({
				label: 'panels.notification.active',
				component: 'NotificationActive',
				value: constants.FEATURE_TABS.ACTIVE_NOTIFICATIONS
			});
			this.tabIndex++;
		}

		this.displayLocation == 'card' ? this.checkHistoricTabElastic() : this.checkHistoricTabStore();

		console.log('mounted device ' + this.displayLocation, this.featureActiveNotifications, this.deviceTabs);
	},
	updated() {
		console.info('updated device index: ', this.deviceObject);
	},
	beforeDestroy() {
		console.warn('beforeDestroy device');
	},
	methods: {
		checkHistoricTabElastic() {
			lastData(constants.HISTORICAL_NOTIFICATIONS_INDEX, query_LastDataHistoric(this.deviceObject.code)).then((result) => {
				console.info('checkHistoricTabElastic result', result, result.message.length > 0);
				if (result.message.length > 0 && typeof result.message !== 'string') {
					this.deviceTabs.push({
						label: 'panels.notification.historic',
						component: 'NotificationHistoric',
						value: constants.FEATURE_TABS.HISTORIC_NOTIFICATIONS
					});
					this.$store.commit('setHasHistoricNotifications', result.message);
					this.tabIndex++;
				} else {
					this.$store.commit('setHasHistoricNotifications', null);
				}
			});
		},
		checkHistoricTabStore() {
			console.info('checkHistoricTabStore result', this.$store.getters.getHasHistoricNotifications);
			if (this.$store.getters.getHasHistoricNotifications && this.$store.getters.getHasHistoricNotifications.length > 0) {
				this.deviceTabs.push({
					label: 'panels.notification.historic',
					component: 'NotificationHistoric',
					value: constants.FEATURE_TABS.HISTORIC_NOTIFICATIONS
				});
				this.tabIndex++;
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
.v-tab {
	max-width: 400px;
}
</style>
