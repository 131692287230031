<template>
	<!--
	<div class="mapzoomreset rounded elevation-1 pa-0">
		<v-tooltip right :color="primaryColor" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text class="btnlevel d-flex justify-center align-center pa-0" v-bind="attrs" @click="resetZoom" v-on="on">
					<v-icon class="fw-500" size="16">fak fa-line-zoom-framing</v-icon>
				</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.resetZoom') }}</span>
		</v-tooltip>
	</div>
	-->

	<v-tooltip top :color="primaryColor" transition="none">
		<template v-slot:activator="{ on, attrs }">
			<v-btn text class="btnzoom pa-0" v-bind="attrs" @click="resetZoom" v-on="on">
				<v-icon class="fw-500" size="16">fa-kit fa-solid-home</v-icon>
			</v-btn>
		</template>
		<span class="fs-regular">{{ $t('map.resetZoom') }}</span>
	</v-tooltip>
</template>

<script>
import ol2map from './ol2map';
import * as olProj from 'ol/proj';
import * as olExtent from 'ol/extent';
import { MIN_ZOOM, MAX_ZOOM, INITIAL_ZOOM } from '@/components/map/sections/map/subcomponents/mapVariables';

import { isSuperAdmin } from '@/api/common';

export default {
	name: 'mapzoomsreset',
	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		zoom: {
			get() {
				if (!this.$store.getters.zoom) {
					return INITIAL_ZOOM;
				}

				return this.$store.getters.zoom | 0; // El zero es para devolver enteros.
			},
			set(value) {
				if (value > MAX_ZOOM || value < MIN_ZOOM) {
					return;
				}

				if (ol2map.map) {
					ol2map.setZoom(value);
					this.$store.commit('setMapZoom', value);
				}
			}
		}
	},
	watch: {},
	methods: {
		resetZoom() {
			console.log('debug reset zoom');

			var polygon = [
				[this.userProperties.nelon, this.userProperties.nelat],
				[this.userProperties.nelon, this.userProperties.swlat],
				[this.userProperties.swlon, this.userProperties.swlat],
				[this.userProperties.swlon, this.userProperties.nelat]
			];

			if (!isSuperAdmin(this.session.profiles[0])) {
				ol2map.zoomToExtent(olProj.transformExtent(new olExtent.boundingExtent(polygon), 'EPSG:4326', 'EPSG:3857'));
				ol2map.setZoom(this.$store.state.mappanel.originalZoom);
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
.mapzoomreset {
	position: absolute;
	bottom: calc(var(--map-control-bottom-position) + 2rem);
	//transition: all 1s ease-out;
	z-index: 3;
	left: 1.5rem;
	height: 32px;
	background-color: var(--negative);
	opacity: 0.85 !important;
	& .btnlevel {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--negative);
		cursor: auto !important;
		font-family: var(--font-family-primary);
		&:hover {
			background: var(--negative) !important;
		}
		&:focus {
			background: var(--negative) !important;
		}
	}
}

.maptools {
	& .btnzoom {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--negative);
	}
	& .btnlevel {
		margin: 0px;
		width: 34px;
		min-width: 34px !important;
		height: 32px;
		background-color: var(--negative);
		cursor: auto !important;
		font-family: var(--font-family-primary);

		&:hover {
			background: var(--negative) !important;
		}
		&:focus {
			background: var(--negative) !important;
		}
	}
}
</style>
