<template>
	<div style="padding-top: 10px; width: 100%">
		<v-form v-model="pollutantsPredictionsForm" ref="form" style="overflow: hidden auto">
			<div row wrap>
				<v-layout wrap class="px-3">
					<v-flex xs12 md6>
						<pui-date-field :label="$t('predictions.date')" toplabel v-model="form.date" :max="Date.now()" no-title></pui-date-field>
					</v-flex>
				</v-layout>
			</div>
		</v-form>
		<!-- Loading animation -->
		<!-- <div class="d-flex justify-center align-center" v-if="loadingAnimation" :style="chartStyle">

		</div> -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<!--  echarts component -->
		<div ref="prechart" class="d-flex justify-center align-center" v-show="!loadingAnimation" :style="chartStyle" style="margin-left: 10px"></div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
import { lastData } from '@/api/databases_API';
import { query_predictions } from '@/utils/queries';
import { formatTimestamp } from '@/api/common';

export default {
	name: 'PredictionsChart',
	data() {
		return {
			width: '100%',
			height: '100%',
			form: {
				dateInterval: [],
				date: null,
				lastInput: null,
				chartData: null
			},
			pollutantsPredictionsForm: false,
			loadingAnimation: false,
			chartStyle: {},
			chartType: 'line',
			prChart: null,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	props: {
		displayLocation: {
			type: String,
			required: true
		},
		deviceObject: {
			type: Object,
			required: true
		},
		pollutants: {
			type: Array,
			required: true
		}
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		maxDate() {
			const currentDate = new Date();
			//const oneDayAgo = new Date();
			//oneDayAgo.setDate(currentDate.getDate() - 1)
			const formattedOneDayAgo = currentDate.toISOString().slice(0, 10);
			return formattedOneDayAgo;
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		}
	},
	watch: {
		selectedTabDevice: {
			handler(newValue, oldValue) {
				console.log('info: tab prediction from', oldValue, 'to', newValue);
				setTimeout(this.resizeChart, 50);
			}
		},
		displayMode: {
			handler(newValue, oldValue) {
				//console.warn('displayMode changed from', oldValue, 'to', newValue);
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
				}
			}
		},
		'form.date': function (newValue, oldValue) {
			if (newValue != oldValue) {
				this.getAllPredictionValues();
			}
		}
	},
	mounted() {
		this.chartStyle = this.displayLocation == 'card' ? { height: '250px', width: '100%' } : { height: 'calc(70vh - 187px)', width: '85vw' };
		if (this.displayLocation == 'dialog') {
			this.restoreChanges();
		}
		this.getAllPredictionValues();
	},
	beforeDestroy() {
		this.deleteChart();
	},
	methods: {
		formatDate(date) {
			console.warn('fecha', date);
			const [day, month, year] = date.split('-').map(Number);
			const reformattedDateString = `${month}-${day}-${year}`;
			const dateObject = new Date(reformattedDateString);
			const timestamp = dateObject.getTime();
			return timestamp;
		},
		getAllPredictionValues() {
			this.loadingAnimation = true;
			let index = 'sp-' + this.deviceObject.organizationacronym.toLowerCase() + '-predictions';
			let query = null;
			let date = null;
			let promise = null;
			let promise2 = null;
			let eDate = new Date();
			let sDate = new Date();
			let dateArray = [];
			if (this.form.date) {
				sDate = new Date(Date.parse(this.form.date));
				eDate = new Date(Date.parse(this.form.date));
			} else {
				this.form.date = eDate.valueOf();
			}
			sDate.setHours(0, 0, 0, 0);
			eDate.setHours(0, 0, 0, 0);
			sDate.setDate(sDate.getDate() - 1);
			query = query_predictions(this.deviceObject.code, sDate.valueOf(), eDate.valueOf());
			//console.log('fechas s e', sDate, eDate);
			//console.log('indice', index);
			//console.log('query', query);
			promise = lastData(index, query);
			promise.then((elasticData) => {
				console.log('debug elastic data', elasticData);
				if (typeof elasticData.message !== 'string' && elasticData.message.length > 0) {
					if (typeof elasticData.message[0] == 'object') {
						//let timestamp = elasticData.message[0]['_source']['dateObserved_timestamp'];
						//let fecha = new Date(timestamp);
						//console.log('date obtained', timestamp, fecha);
						//console.log(elasticData.message[0])
						const groupedData = {};
						this.form.lastInput = elasticData.message[0]['_source']['prediction']['last-input-day'];
						let predictions = elasticData.message[0]['_source']['prediction']['predictions'];
						for (const period in predictions) {
							const pollutantData = predictions[period];
							for (const pollutant in pollutantData) {
								if (pollutant === 'date') {
									date = this.formatDate(pollutantData[pollutant]);
									dateArray.push(formatTimestamp(date, this.userProperties));
								}
								if (!groupedData[pollutant]) {
									groupedData[pollutant] = [];
								}
								let value = parseFloat(pollutantData[pollutant]);
								groupedData[pollutant].push(value.toFixed(2));
							}
						}
						if (elasticData.message.length > 0) {
							this.form.chartData = groupedData;
							this.form.dateInterval = dateArray;
						}
						this.commitChanges();
						if (this.form.chartData) this.initChart();
					}
				} else {
					sDate.setDate(sDate.getDate() - 1);
					eDate.setDate(eDate.getDate() - 1);
					query = query_predictions(this.deviceObject.code, sDate.valueOf(), eDate.valueOf());
					promise2 = lastData(index, query);
					promise2.then((elasticData) => {
						if (typeof elasticData.message !== 'string' && typeof elasticData.message[0] == 'object') {
							//let timestamp = elasticData.message[0]['_source']['dateObserved_timestamp'];
							//let fecha = new Date(timestamp);
							//console.log('date obtained', timestamp, fecha);
							//console.log(elasticData.message[0])
							const groupedData = {};
							this.form.lastInput = elasticData.message[0]['_source']['prediction']['last-input-day'];
							let predictions = elasticData.message[0]['_source']['prediction']['predictions'];
							for (const period in predictions) {
								const pollutantData = predictions[period];
								for (const pollutant in pollutantData) {
									if (pollutant === 'date') {
										date = this.formatDate(pollutantData[pollutant]);
										dateArray.push(formatTimestamp(date, this.userProperties));
									}
									if (!groupedData[pollutant]) {
										groupedData[pollutant] = [];
									}
									let value = parseFloat(pollutantData[pollutant]);
									groupedData[pollutant].push(value.toFixed(2));
								}
							}
							if (elasticData.message.length > 0) {
								this.form.chartData = groupedData;
								this.form.dateInterval = dateArray;
							}
							this.commitChanges();
							if (this.form.chartData) this.initChart();
						} else {
							this.deleteChart();
							this.$puiNotify.info(this.$t('predictions.noData'));
							this.loadingAnimation = false;
						}
					});
				}
			});
		},
		resizeChart() {
			//console.log('info: resize chart ' + this.index);
			if (this.prChart) {
				this.prChart.resize();
			}
		},
		deleteChart() {
			this.form.chartData = null;
			if (!this.prChart) {
				return;
			}
			this.prChart.dispose();
			this.prChart = null;
			window.removeEventListener('resize', this.resizeChart);
		},
		commitChanges() {
			//console.log('COMMITED')
			if (this.displayMode == this.displayLocation) {
				this.$store.commit('setFormPredictionsObject', this.form);
				if (this.form.chartData) this.initChart();
			} else {
				//console.log('log: not commiting changes ' + this.displayLocation);
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				this.form = this.$store.getters.getPredictionsStore;
				if (this.form.chartData) this.initChart();
			} else {
				//console.log('log: not restoring changes ' + this.displayLocation);
			}
		},
		initChart() {
			this.deleteChart();
			var option;
			this.loadingAnimation = false;

			this.prChart = echarts.init(this.$refs['prechart']);
			window.addEventListener('resize', this.resizeChart);
			option = {};
			//console.warn('log: chart option', option);
			let valueso3 = [];
			let valuesno2 = [];
			let valuesso2 = [];
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let toolbox = {};
			let dataZoom = [];
			let tooltip = {};
			let legend = {};
			let title = {};
			//console.warn("pollutant")
			//console.log(this.pollutantObjList)
			let o3 = this.pollutants.find((item) => item.elasticacronym === 'o3');
			let pollutantO3Title = o3.symbol && o3.symbol.trim().length > 0 ? o3.name + ' (' + o3.symbol + ')' : o3.name;
			valueso3 = this.form.chartData['o3'];
			let no2 = this.pollutants.find((item) => item.elasticacronym === 'no2');
			let pollutantNO2Title = no2.symbol && no2.symbol.trim().length > 0 ? no2.name + ' (' + no2.symbol + ')' : no2.name;
			valuesno2 = this.form.chartData['no2'];
			let so2 = this.pollutants.find((item) => item.elasticacronym === 'so2');
			let pollutantSO2Title = so2.symbol && so2.symbol.trim().length > 0 ? so2.name + ' (' + so2.symbol + ')' : so2.name;
			valuesso2 = this.form.chartData['so2'];
			//console.warn('DATOS')
			//console.log(values)
			let dateTitle = this.form.lastInput.split('-');
			if (dateTitle[0].length == 1) {
				dateTitle[0] = '0' + dateTitle[0];
			}
			title = {
				text: dateTitle[0] + '-' + dateTitle[1] + '-' + dateTitle[2]
			};
			series = [
				{
					name: pollutantO3Title,
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: o3.symbol ? '{@score}' + o3.symbol : '{@score}'
					},
					data: valueso3
				},
				{
					name: pollutantNO2Title,
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: no2.symbol ? '{@score}' + no2.symbol : '{@score}'
					},
					data: valuesno2
				},
				{
					name: pollutantSO2Title,
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: so2.symbol ? '{@score}' + so2.symbol : '{@score}'
					},
					data: valuesso2
				}
			];
			xAxis = {
				type: 'category',
				data: this.form.dateInterval
			};
			yAxis = [{ type: 'value' }];
			toolbox = {
				right: 10,
				feature: {
					restore: {},
					saveAsImage: {}
				}
			};
			dataZoom = [
				{
					startValue: this.form.dateInterval[0]
				},
				{
					type: 'inside'
				}
			];
			tooltip = {
				trigger: 'axis'
			};
			legend = {};
			option['legend'] = legend;
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = toolbox;
			option['dataZoom'] = dataZoom;
			option['tooltip'] = tooltip;
			option['title'] = title;
			//console.log(option);
			option && this.prChart.setOption(option);
		}
	}
};
</script>

<style scoped>
.topOptions {
	display: flex;
	flex: auto;
	justify-content: space-between;
	align-content: center;
}
</style>
